import { numberFormat } from "../../all_ppt_render";
import { generateTitleAndSubtitlePVImmo, titleLabel } from "./hypothese";

export const generatePageDetail2 = (pptx, slide, hypothese, ir, surtaxe, cehr, ifi, valeurForfait) => {
    const x = 0.7;
    const y = 1;
    const rowH = 0.3;//0.35;
    const emptyLineH = 0.003;
    const rowsHeight = [ rowH, rowH ];
    const rowsHeight2 = [];


    const rowOptions = {
        fontSize: 11,
        fontFace: 'Roboto',
        valign: 'middle',
        margin: [ 0, 10, 0, 5 ]
    }

    const data = [
        [
            { text: "SURTAXE", options: { ...rowOptions, bold: true, color: 'F06460', fill: 'FFD7D5', colspan: 2 } },
        ],
        [
            { text: "Plus-value imposable (par cédant)", options: { ...rowOptions, fill: 'F2F2F2' } },
            { text: `${surtaxe.plus_value_imposable ? numberFormat(surtaxe.plus_value_imposable) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "Surtaxe (par cédant)", options: { ...rowOptions, fill: 'F2F2F2', bold: true, color: 'F06460' } },
            { text: `${surtaxe.sur_taxe_par_cedant ? numberFormat(surtaxe.sur_taxe_par_cedant) : 0 } €`, options: { ...rowOptions, color: 'F06460', bold: true, fill: 'F2F2F2', align: 'right' } }
        ],
    ];

    if(hypothese.bien_detenu){
        data.push(
            [
                { text: "Surtaxe totale", options: { ...rowOptions, fill: 'F2F2F2', bold: true, color: 'F06460' } },
                { text: `${surtaxe.sur_taxe_total ? numberFormat(surtaxe.sur_taxe_total) : 0 } €`, options: { ...rowOptions, color: 'F06460', bold: true, fill: 'F2F2F2', align: 'right' } }
            ],
        );
    }

    const data2 = [
        [
            { text: "CONTRIBUTION EXCEPTIONNELLE SUR LES HAUTS REVENUS (CEHR)", options: { ...rowOptions, bold: true, color: 'F06460', fill: 'FFD7D5', colspan: 2 } },
        ],
        [
            { text: "Seuil d’imposition à la CEHR", options: { ...rowOptions, fill: 'F2F2F2' } },
            { text: `${cehr.seuil_imposition_cehr ? numberFormat(cehr.seuil_imposition_cehr) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "Revenu fiscal de référence hors plus-value IMMO", options: { ...rowOptions, fill: 'F2F2F2' } },
            { text: `${cehr.rfr_annee_hors_pv ? numberFormat(cehr.rfr_annee_hors_pv) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "Réintégration plus-value immobilière", options: { ...rowOptions, fill: 'F2F2F2' } },
            { text: `${cehr.reintegration_plus_value_immo ? numberFormat(cehr.reintegration_plus_value_immo) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "Revenu fiscal de référence avec plus-value IMMO", options: { ...rowOptions, fill: 'F2F2F2' } },
            { text: `${cehr.rfr_avec_pv_immo ? numberFormat(cehr.rfr_avec_pv_immo) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "CEHR sur plus-value IMMO avant lissage éventuel", options: { ...rowOptions, fill: 'F2F2F2', bold: true, color: 'F06460' } },
            { text: `${cehr.cehr_sur_pv_immo_avant_lissage ? numberFormat(cehr.cehr_sur_pv_immo_avant_lissage) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', bold: true, color: 'F06460' } }
        ],
    ];

    if(hypothese.activer_mecanisme_lissage_cehr){
        data2.push(
            [
                { text: "Base imposable pour lissage", options: { ...rowOptions, fill: 'F2F2F2' } },
                { text: `${cehr.base_imposable_lissage ? numberFormat(cehr.base_imposable_lissage) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ],
            [
                { text: "CEHR sur plus-value IMMO après lissage", options: { ...rowOptions, fill: 'F2F2F2', bold: true, color: 'F06460' } },
                { text: `${cehr.cehr_annee_n_hors_pv_immo_apres_lissage ? numberFormat(cehr.cehr_annee_n_hors_pv_immo_apres_lissage) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', bold: true, color: 'F06460' } }
            ]
        )
    }

    const data3 = [
        [
            { text: "IMPACT SUR LE PLAFONNEMENT IFI", options: { ...rowOptions, bold: true, color: 'F06460', fill: 'FFD7D5', colspan: 2 } },
        ],
        [
            { text: "Plus-value prise en compte pour le calcul du plafonnement de l’IFI", options: { ...rowOptions, fill: 'F2F2F2' } },
            { text: `${ifi.pv_compte_calcul_plafonnement_ifi ? numberFormat(ifi.pv_compte_calcul_plafonnement_ifi) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ]
    ]

    generateTitleAndSubtitlePVImmo(
        "SIMULATION",
        pptx,
        slide,
        {
            text: titleLabel(hypothese.type_cession),
            width: 6.55
        }
    );
    
    // PARTIE GAUCHE
    slide.addText('IMPOSITION DE LA PLUS-VALUE IMMOBILIÈRE', {
        x: x - 0.3,
        y: y + 0.4,
        w: 5,
        h: 0.4,
        fontFace: 'Roboto',
        align: 'left',
        fontSize: 13,
        bold: true,
        color: '4472C4'
    })

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x - 0.2,
        y: y + 0.8,
        fill: '4472C4',
        w: 0.5,
        h: 0.05,
        rectRadius: 0.4,
        line: {
            color: '4472C4'
        }
    });

    // Surtaxe
    slide.addTable(data, {
        x: x - 0.2,
        y: y + 1.1,
        colW: [ 4, 1.3 ],
        rowH: 0.3
    })

    // CEHR
    slide.addTable(data2, {
        x: x - 0.2,
        y: y + 2.5,
        colW: [ 4, 1.3 ],
        rowH: 0.3
    })
    
    // IFI
    if(hypothese.impact_plafonnement_ifi){
        slide.addTable(data3, {
            x: x - 0.2,
            y: y + 5.1,
            colW: [ 4, 1.3 ],
            rowH: 0.3
        })
    }

    // PARTIE DROITE
    slide.addText("DÉTAIL FISCALITÉ", {
        shape: pptx.ShapeType.roundRect,
        fill: '4472C4',
        rectRadius: 0.05,
        x: x + 8.56,
        y: y + 0.4,
        align: 'center',
        valign: 'middle',
        w: 1.8,
        h: 0.3,
        fontFace: 'Roboto',
        fontSize: 12,
        bold: true,
        color: 'FFFFFF'
    });

    const commentaires1 = [
        { text: "Commentaires", options: { breakLine: true, bold: true } },
        { text: "", options: { breakLine: true } },
    ]

    if([1].includes(hypothese.nature_bien_cede)){
        commentaires1.push(
            { text: `Pas de surtaxe sur la plus-value de cession de terrains à bâtir.`, options: { breakLine: true, bullet: { code: '25CF' } } }
        )
    }

    const testValue = surtaxe.plus_value_imposable ? surtaxe.plus_value_imposable : 0;

    if([0, 2, 3].includes(hypothese.nature_bien_cede) && (testValue < 50000 )){
        commentaires1.push(
            { text: `Pas de surtaxe sur la plus-value immobilière nette imposable à l'IR n'excédant pas 50 000 €.`, options: { breakLine: true, bullet: { code: '25CF' } } }
        );
    }
    
    if([0, 2, 3].includes(hypothese.nature_bien_cede) && (testValue > 50000 )){
        commentaires1.push(
            { text: `Surtaxe sur la plus-value immobilière nette imposable à l'IR supérieure à 50 000 €.`, options: { breakLine: true, bullet: { code: '25CF' } } }
        );
    }
    
    const commentaires2 = [
        { text: "Commentaire", options: { breakLine: true, bold: true } },
        { text: "", options: { breakLine: true } },
        { text: `Pour le calcul de la CEHR, prise en compte de la plus-value immobilière de cession, `, options: { breakLine: false } },
        { text: `pour son montant net imposable à l'IR`, options: { breakLine: false, bold: true } },
        { text: ` : après abattement ou exonération s'agissant de la résidence principale.`, options: { breakLine: false } },
    ]
    
    const commentaires3 = [
        { text: "Commentaire", options: { breakLine: true, bold: true } },
        { text: "", options: { breakLine: true } },
        { text: `Pour le calcul du plafonnement de l'IFI, prise en compte de la plus-value immobilière de cession,`, options: { breakLine: false } },
        { text: ` pour son montant brut `, options: { breakLine: false, bold: true } },
        { text: `: avant abattement et avant exonération s'agissant de la résidence principale.`, options: { breakLine: false } },
    ]

    if(hypothese.type_cession !== undefined){
        slide.addText(
            commentaires1,{
                x: x + 5.5,
                y: y + 1.1,
                fontFace: 'Roboto',
                fontSize: 11,
                color: '44546A',
                fill: 'FFF8E2',
                valign: 'top',
                w: 4.9,
                h: 1
            }
        )
    }
    
    slide.addText(
        commentaires2,{
            x: x + 5.5,
            y: y + 2.5,
            fontFace: 'Roboto',
            align: 'justify',
            fontSize: 11,
            color: '44546A',
            fill: 'FFF8E2',
            valign: 'top',
            w: 4.9,
            h: 1.4
        }
    )
    
    if(hypothese.impact_plafonnement_ifi){
        slide.addText(
            commentaires3,{
                x: x + 5.5,
                y: y + 5.1,
                fontFace: 'Roboto',
                align: 'justify',
                fontSize: 11,
                color: '44546A',
                fill: 'FFF8E2',
                valign: 'top',
                w: 4.9,
                h: 1.2
            }
        )
    }
}