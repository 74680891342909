import { generateTitleAndSubtitle, numberFormat } from "../../all_ppt_render";
import titleImage from '../../../assets/images/Export/IFI/HEADER.png';
import Calculatrice from '../../../assets/images/Export/IFI/CALCULATOR.png';
import Info from '../../../assets/images/Export/IFI/INFO.png';

export const natureBienPvImmo = (index) => {
    switch (index) {
        case 0:
            return [
                { text: "Autres biens immobiliers", options: { breakLine: true } },
                { text: "(hors résidence principale)" }
            ];
        case 1:
            return "Terrains à bâtir";
        case 2:
            return "Bois et forêts";
        default:
            return "Construction";
    }
}

export const generateTitleAndSubtitlePVImmo = (type, pptx, slide, title, subtitle, secondSubtitle) => {
    if (secondSubtitle && secondSubtitle.text && secondSubtitle.width && secondSubtitle.x) {
        slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
            x: secondSubtitle.x,
            y: 0.3,
            fill: '9DC3E6',
            w: secondSubtitle.width,
            h: 0.8,
            rectRadius: 0.4,
            line: {
                color: '9DC3E6'
            }
        });

        slide.addText(secondSubtitle.text, {
            x: secondSubtitle.x,
            y: 0.3,
            w: secondSubtitle.width,
            h: 0.8,
            align: 'center',
            fontFace: 'Roboto',
            fontSize: 14,
            color: 'FFFFFF',
            bold: true
        })
    }

    // Texte en bleue ciel
    if (subtitle && subtitle.text && subtitle.width && subtitle.x) {
        slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
            x: subtitle.x,
            y: 0.3,
            fill: '4472C4',
            w: subtitle.width,
            h: 0.8,
            rectRadius: 0.4,
            line: {
                color: '4472C4'
            }
        });

        slide.addText(subtitle.text, {
            x: subtitle.x,
            y: 0.3,
            w: subtitle.width,
            h: 0.8,
            align: 'center',
            fontFace: 'Roboto',
            fontSize: 14,
            color: 'FFFFFF',
            bold: true
        })
    }
    // Fin

    // Texte en bleu marine
    if (title && title.text && title.width) {
        slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
            x: 0.6,
            y: 0.3,
            fill: '28366D',
            w: title.width,
            h: 0.8,
            rectRadius: 0.4,
            line: {
                color: '28366D'
            }
        });

        slide.addText(title.text, {
            x: 1.55,
            y: 0.3,
            w: title.width,
            h: 0.8,
            fontFace: 'Roboto',
            color: 'FFFFFF'
        })
    }
    // Fin texte en Bleu marine

    slide.addImage({
        path: titleImage,
        x: 0.35,
        y: 0.3,
        w: 1.2,
        h: 0.85
    })

    const image = type === 'SIMULATION' ? Calculatrice : Info;

    const calculatriceX = 0.62;
    const calculatriceY = 0.5;
    const calculatriceWidth = 0.3;
    const calculatriceHeight = 0.4;

    const infoX = 0.72;
    const infoY = 0.55;
    const infoWidth = 0.1;
    const infoHeight = 0.3;

    slide.addImage({
        path: image,
        x: type === 'SIMULATION' ? calculatriceX : infoX,
        y: type === 'SIMULATION' ? calculatriceY : infoY,
        w: type === 'SIMULATION' ? calculatriceWidth : infoWidth,
        h: type === 'SIMULATION' ? calculatriceHeight : infoHeight,
    })
}

export const titleLabel = (type) => {
    switch (type) {
        case 0:
            return [
                { text: "CESSION DIRECTE", options: { bold: true, breakLine: true, fontSize: 14 } },
                { text: "", options: { breakLine: true } },
                { text: "Cession d'un actif immobilier détenu à titre personnel", options: { fontSize: 12 } }
            ]

        case 1:
            return [
                { text: "CESSION INDIRECTE", options: { bold: true, breakLine: true, fontSize: 14 } },
                { text: "", options: { breakLine: true } },
                { text: "Cession d'un actif immobilier détenu au travers d'une SCI soumise à l'IR", options: { fontSize: 12 } }
            ]

        default:
            return [
                { text: "CESSION DE PARTS DE SCI SOUMISE À L’IR", options: { bold: true, fontSize: 14 } }
            ]
    }
}

export const generatePageHypothese = (pptx, slide, hypothese, resultat) => {
    const x = 0.7;
    const y = 1;
    const conditionConstruction = hypothese.nature_bien_cede === 3;
    const construction = hypothese.construction ? hypothese.construction : {};

    const rowOptions = {
        fontSize: 11,
        fontFace: 'Roboto',
        valign: 'middle',
        margin: [0, 10, 0, 0],
        border: [
            {
                pt: 1,
                color: 'FFFFFF'
            },
            {
                pt: 1,
                color: 'FFFFFF'
            },
            {
                pt: 1,
                color: 'FFFFFF'
            },
            {
                pt: 1,
                color: 'FFFFFF'
            }
        ]
    }

    // Situations habituelles
    const data = [];
    const rowsHeightData = [];
    const rowH = 0.35;
    const emptyRowH = 0.1;
    // Autres que construction
    if ([0, 1].includes(hypothese.type_cession)) {
        rowsHeightData.push(hypothese.nature_bien_cede === 0 ? 0.45 : rowH);// 0.45 pour Autres actifs immobiliers
        data.push(
            [
                { text: "Nature du bien cédé", options: { ...rowOptions } },
                { text: "", options: { ...rowOptions } },
                { text: natureBienPvImmo(hypothese.nature_bien_cede), options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ]
        )
    }

    if (hypothese.type_cession === 0 && hypothese.nature_bien_cede === 2) {
        rowsHeightData.push(rowH);
        data.push(
            [
                { text: "Nb d'hectares vendus", options: { ...rowOptions } },
                { text: "", options: { ...rowOptions } },
                { text: numberFormat(hypothese.nb_hectare_vendus), options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ]
        )
    }

    if (hypothese.type_cession !== undefined) {
        rowsHeightData.push(emptyRowH);
        data.push(
            [
                { text: "", options: { ...rowOptions } },
                { text: "", options: { ...rowOptions } },
                { text: "", options: { ...rowOptions } }
            ]
        )
    }

    rowsHeightData.push(rowH);
    data.push(
        [
            { text: "Durée de détention", options: { ...rowOptions, bold: true, underline: { color: '000000', style: 'sng' } } },
            { text: "", options: { ...rowOptions } },
            { text: "", options: { ...rowOptions } }
        ]
    );

    if (hypothese.choix_duree === 0) {
        rowsHeightData.push(rowH);
        data.push(
            [
                { text: "Nb d'années pleines de détention", options: { ...rowOptions } },
                { text: "", options: { ...rowOptions } },
                { text: `${hypothese.nb_annee_pleines_detention}`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ]
        )
    }
    else {
        rowsHeightData.push(rowH, rowH);
        data.push(
            [
                { text: "Date de cession", options: { ...rowOptions } },
                { text: "", options: { ...rowOptions } },
                { text: `${hypothese.date_cession}`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ],
            [
                { text: "Date d'acquisition", options: { ...rowOptions } },
                { text: "", options: { ...rowOptions } },
                { text: `${hypothese.date_acquisition}`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ],
        )
    }

    // A partir du Prix de cession
    rowsHeightData.push(emptyRowH, rowH, rowH, rowH);
    data.push(
        [
            { text: "", options: { ...rowOptions } },
            { text: "", options: { ...rowOptions } },
            { text: "", options: { ...rowOptions } }
        ],
        [
            { text: "Prix de cession", options: { ...rowOptions, bold: true, underline: { color: '000000', style: 'sng' } } },
            { text: "", options: { ...rowOptions } },
            { text: `${numberFormat(hypothese.prix_cession)} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "Frais de cession", options: { ...rowOptions } },
            { text: "", options: { ...rowOptions } },
            { text: `${numberFormat(hypothese.frais_cession)} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "Prix de cession corrigé", options: { ...rowOptions, bold: true } },
            { text: "", options: { ...rowOptions } },
            { text: `${resultat.prix_cession_corrige ? numberFormat(resultat.prix_cession_corrige) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
    )

    // A partir du Prix d'acquisition
    rowsHeightData.push(emptyRowH, rowH, rowH, rowH);
    data.push(
        [
            { text: "", options: { ...rowOptions } },
            { text: "", options: { ...rowOptions } },
            { text: "", options: { ...rowOptions } }
        ],
        [
            { text: "Prix d'acquisition", options: { ...rowOptions, bold: true, underline: { color: '000000', style: 'sng' } } },
            { text: "", options: { ...rowOptions } },
            { text: `${numberFormat(hypothese.prix_acquisition)} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "Charges et indemnités", options: { ...rowOptions } },
            { text: "", options: { ...rowOptions } },
            { text: `${numberFormat(hypothese.charge_indemnite)} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "Frais d'acquisition", options: { ...rowOptions } },
            { text: `${hypothese.frais_acquisition === 0 ? "Forfait 7,5%" : "Frais réels"}`, options: { ...rowOptions, fill: 'F2F2F2', align: 'center' } },
            { text: `${numberFormat(hypothese.frais_acquisition === 0 ? resultat.frais_acquisition_part1 ? resultat.frais_acquisition_part1 : 0 : hypothese.frais_acquisition_part2)} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
    )

    if ([0, 1].includes(hypothese.type_cession)) {
        if(hypothese.nature_bien_cede !== 2){
            rowsHeightData.push(rowH);
            data.push(
                [
                    { text: "Frais de travaux", options: { ...rowOptions } },
                    { text: `${hypothese.frais_travaux === 0 ? "Forfait 15%" : "Frais réels"}`, options: { ...rowOptions, fill: 'F2F2F2', align: 'center' } },
                    { text: `${numberFormat(hypothese.frais_travaux === 0 ? resultat.frais_travaux_part1 ? resultat.frais_travaux_part1 : 0 : hypothese.frais_travaux_part2)} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
                ]
            )
        }

        rowsHeightData.push(rowH);
        data.push(
            [
                { text: "Frais de voirie, réseaux et distribution", options: { ...rowOptions } },
                { text: "", options: { ...rowOptions } },
                { text: `${numberFormat(hypothese.frais_voirie)} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ]
        )
    }

    rowsHeightData.push(rowH);
    data.push(
        [
            { text: "Prix d’acquisition corrigé", options: { ...rowOptions, bold: true } },
            { text: "", options: { ...rowOptions } },
            { text: `${resultat.prix_acquisition_corrige ? numberFormat(resultat.prix_acquisition_corrige) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ]
    )

    // Si la nature du bien céde est CONSTRUCTION
    const dataConstruction = [
        [
            { text: "Nature du bien cédé", options: { ...rowOptions, colspan: 4 } },
            { text: "Construction", options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } }
        ],
        [
            { text: "Durée de détention", options: { ...rowOptions, bold: true, underline: { color: '000000', style: 'sng' }, colspan: 2 } },
            { text: "Terrain", options: { ...rowOptions, color: 'FFFFFF', align: 'right', fill: '8497B0', bold: true } },
            { text: "", options: { ...rowOptions } },
            { text: "Construction", options: { ...rowOptions, color: 'FFFFFF', align: 'right', fill: '8497B0', bold: true } },
        ]
    ];

    const rowsHeightConstruction = [rowH, rowH];

    const choixDureeConstruction = conditionConstruction && hypothese.construction && hypothese.construction.choix_duree !== undefined ? hypothese.construction.choix_duree : null;

    // à revoir la condition
    if (hypothese.choix_duree === 0 || choixDureeConstruction === 0) {
        rowsHeightConstruction.push(rowH);
        dataConstruction.push(
            [
                { text: "Nb d'années pleines de détention", options: { ...rowOptions, colspan: 2 } },
                { text: `${hypothese.choix_duree === 0 ? hypothese.nb_annee_pleines_detention : '-'}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
                { text: "", options: { ...rowOptions } },
                { text: `${choixDureeConstruction === 0 ? construction.nb_annee_pleines_detention : '-'}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } }
            ]
        )
    }

    // à revoir la condition
    if (hypothese.choix_duree === 1 || choixDureeConstruction === 1) {
        rowsHeightConstruction.push(rowH, rowH);
        dataConstruction.push(
            [
                { text: "Date de cession", options: { ...rowOptions, colspan: 2 } },
                { text: `${hypothese.choix_duree === 1 ? hypothese.date_cession : '-'}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
                { text: "", options: { ...rowOptions } },
                { text: `${choixDureeConstruction === 1 ? construction.date_cession : '-'}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } }
            ],
            [
                { text: "Date de d'acquisition", options: { ...rowOptions, colspan: 2 } },
                { text: `${hypothese.choix_duree === 1 ? hypothese.date_acquisition : '-'}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
                { text: "", options: { ...rowOptions } },
                { text: `${choixDureeConstruction === 1 ? construction.date_acquisition : '-'}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } }
            ]
        )
    }

    // Partie Prix de Cession
    rowsHeightConstruction.push(emptyRowH, rowH, rowH, rowH, emptyRowH, rowH, rowH, rowH, rowH, rowH, rowH);
    dataConstruction.push(
        [
            { text: "", options: { ...rowOptions, colspan: 5 } }
        ],
        [
            { text: "Prix de cession", options: { ...rowOptions, bold: true, underline: { color: '000000', style: 'sng' }, colspan: 2 } },
            { text: `${numberFormat(hypothese.prix_cession)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: "", options: { ...rowOptions } },
            { text: `${numberFormat(construction.prix_cession)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
        ],
        [
            { text: "Frais de cession", options: { ...rowOptions, colspan: 2 } },
            { text: `${numberFormat(hypothese.frais_cession)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: "", options: { ...rowOptions } },
            { text: `${numberFormat(construction.frais_cession)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
        ],
        [
            { text: "Prix de cession corrigé", options: { ...rowOptions, bold: true, colspan: 2 } },
            { text: `${resultat.prix_cession_corrige ? numberFormat(resultat.prix_cession_corrige) : 0} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: "", options: { ...rowOptions } },
            { text: `${resultat.prix_cession_corrige_construction ? numberFormat(resultat.prix_cession_corrige_construction) : 0} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
        ]
    )

    // Partie Prix d'acquisition
    dataConstruction.push(
        [
            { text: "", options: { ...rowOptions, colspan: 5 } }
        ],
        [
            { text: "Prix d'acquisition", options: { ...rowOptions, bold: true, underline: { color: '000000', style: 'sng' }, colspan: 2 } },
            { text: `${numberFormat(hypothese.prix_acquisition)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: "", options: { ...rowOptions } },
            { text: `${numberFormat(construction.prix_acquisition)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
        ],
        [
            { text: "Charges et indemnités", options: { ...rowOptions, colspan: 2 } },
            { text: `${numberFormat(hypothese.charge_indemnite)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: "", options: { ...rowOptions } },
            { text: `${numberFormat(construction.charge_indemnite)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
        ],
        [
            { text: "Frais d'acquisition", options: { ...rowOptions } },
            { text: `${hypothese.frais_acquisition === 0 ? '7,5%' : "Réels"}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: `${numberFormat(hypothese.frais_acquisition === 0 ? resultat.frais_acquisition_part1 ? resultat.frais_acquisition_part1 : 0 : hypothese.frais_acquisition_part2)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: `${construction.frais_acquisition === 0 ? '7,5%' : "Réels"}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: `${numberFormat(construction.frais_acquisition === 0 ? resultat.frais_acquisition_part1_construction ? resultat.frais_acquisition_part1_construction : 0 : construction.frais_acquisition_part2)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
        ],
        [
            { text: "Frais de travaux", options: { ...rowOptions } },
            { text: `${hypothese.frais_travaux === 0 ? '15%' : 'Réels'}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: `${numberFormat(hypothese.frais_travaux === 0 ? resultat.frais_travaux_part1 ? resultat.frais_travaux_part1 : 0 : hypothese.frais_travaux_part2)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: `${construction.frais_travaux === 0 ? '15%' : 'Réels'}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: `${numberFormat(construction.frais_travaux === 0 ? resultat.frais_travaux_part1_construction ? resultat.frais_travaux_part1_construction : 0 : construction.frais_travaux_part2)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
        ],
        [
            { text: "Frais de voirie, réseaux et distribution", options: { ...rowOptions, colspan: 2 } },
            { text: `${numberFormat(hypothese.frais_voirie)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: "", options: { ...rowOptions } },
            { text: `${numberFormat(construction.frais_voirie)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
        ],
        [
            { text: "Prix d'acquisition corrigé", options: { ...rowOptions, bold: true, colspan: 2 } },
            { text: `${resultat.prix_acquisition_corrige ? numberFormat(resultat.prix_acquisition_corrige) : 0} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
            { text: "", options: { ...rowOptions } },
            { text: `${resultat.prix_acquisition_corrige_construction ? numberFormat(resultat.prix_acquisition_corrige_construction) : 0} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
        ],
    )

    const data2 = [];
    const rowsHeightData2 = [];

    if ([1, 3].includes(hypothese.nature_bien_cede)) {
        rowsHeightData2.push(rowH, rowH, emptyRowH);
        data2.push(
            [
                { text: "Plus-value imposable à l’IR et aux PS", options: { ...rowOptions, bold: true } },
                { text: ``, options: { ...rowOptions } }
            ],
            [
                { text: "Abattement exceptionnel ?", options: { ...rowOptions } },
                { text: `${hypothese.abattemennt_exceptionnel ? `${hypothese.abattemennt_exceptionnel} %` : "Aucun"}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } }
            ],
            [
                { text: "", options: { ...rowOptions } },
                { text: "", options: { ...rowOptions } }
            ],
        );
    }

    // rowsHeightData2.push(rowH, rowH, emptyRowH, rowH, rowH, rowH, rowH);
    const conditionAffichageBienDetenu = hypothese.type_cession === 0 ? hypothese.nature_bien_cede !== 1 : hypothese.type_cession === 1 ? false : true;

    if(conditionAffichageBienDetenu){
        rowsHeightData2.push(rowH, rowH, emptyRowH);
        data2.push(
            [
                { text: "Plus-value imposable à la Surtaxe", options: { ...rowOptions, bold: true } },
                { text: "", options: { ...rowOptions } }
            ],
            [
                { text: "Bien détenu en indivision ou communauté ?", options: { ...rowOptions } },
                { text: `${hypothese.bien_detenu ? 'Oui' : 'Non'}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } }
            ],
            [
                { text: "", options: { ...rowOptions } },
                { text: "", options: { ...rowOptions } }
            ],
        );
    }

    rowsHeightData2.push(rowH, rowH, rowH, rowH);
    data2.push(
        [
            { text: "Plus-value imposable à la CEHR", options: { ...rowOptions, bold: true } },
            { text: "", options: { ...rowOptions } }
        ],
        [
            { text: "Situation familiale", options: { ...rowOptions } },
            { text: `${hypothese.situation_familiale === 0 ? "Couple marié ou pacsé" : "Personne seule"}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } }
        ],
        [
            { text: "RFR année (N) hors PV IMMO", options: { ...rowOptions } },
            { text: `${numberFormat(hypothese.rfr_annee_hors_pv)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } }
        ],
        [
            { text: "Activer le mécanisme de lissage de la CEHR", options: { ...rowOptions } },
            { text: `${hypothese.activer_mecanisme_lissage_cehr ? "Oui" : "Non"}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } }
        ],
    );

    if (hypothese.activer_mecanisme_lissage_cehr) {
        rowsHeightData2.push(rowH, rowH);
        data2.push(
            [
                { text: "RFR année (N-1)", options: { ...rowOptions } },
                { text: `${numberFormat(hypothese.rfr_annee_n_1)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } }
            ],
            [
                { text: "RFR année (N-2)", options: { ...rowOptions } },
                { text: `${numberFormat(hypothese.rfr_annee_n_2)} €`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } }
            ]
        )
    }

    /*rowsHeightData2.push(emptyRowH, rowH);
    data2.push(
        [
            { text: "", options: { ...rowOptions } },
            { text: "", options: { ...rowOptions } },
        ],
        [
            { text: "Impact sur le plafonnement de l'IFI", options: { ...rowOptions, bold: true } },
            { text: `Calcul ${hypothese.impact_plafonnement_ifi ? 'activé' : 'désactivé'}`, options: { ...rowOptions, align: 'right', fill: 'F2F2F2' } },
        ]
    )*/

    generateTitleAndSubtitlePVImmo(
        "SIMULATION",
        pptx,
        slide,
        {
            text: titleLabel(hypothese.type_cession),
            width: 6.55
        }
    )

    // PARTIE GAUCHE
    slide.addText('CARACTÉRISTIQUES', {
        x: x - (hypothese.nature_bien_cede === 0 ? 0.4 : 0.3),
        y: y + 0.4,
        w: 3,
        h: 0.4,
        fontFace: 'Roboto',
        align: 'left',
        fontSize: 13,
        bold: true,
        color: '4472C4'
    })

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x - (hypothese.nature_bien_cede === 0 ? 0.3 : 0.2),
        y: y + 0.8,
        fill: '4472C4',
        w: 0.5,
        h: 0.05,
        rectRadius: 0.4,
        line: {
            color: '4472C4'
        }
    });


    /** Nature du bien => Construction */
    if (conditionConstruction) {
        slide.addTable(dataConstruction, {
            x: x - 0.2,
            y: y + 1,
            colW: [1.9, 0.55, 1.3, 0.55, 1.3],
            rowH: rowsHeightConstruction
        })
    }
    else {
        slide.addTable(data, {
            x: x - (hypothese.nature_bien_cede === 0 ? 0.3 : 0.2),
            y: y + 1,
            colW: [2.7, 1.3, hypothese.nature_bien_cede === 0 ? 2 : 1.3],
            rowH: rowsHeightData
        })
    }

    // PARTIE DROITE
    slide.addText('CALCUL DE LA PLUS-VALUE IMPOSABLE', {
        x: x + 5.8,
        y: y + 0.4,
        w: 4,
        h: 0.4,
        fontFace: 'Roboto',
        align: 'left',
        fontSize: 13,
        bold: true,
        color: '4472C4'
    })

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x + 5.9,
        y: y + 0.8,
        fill: '4472C4',
        w: 0.5,
        h: 0.05,
        rectRadius: 0.4,
        line: {
            color: '4472C4'
        }
    });

    slide.addTable(data2, {
        x: x + 5.9,
        y: y + 1,
        colW: [3.25, 1.3],
        rowH: rowsHeightData2
    })


}