import { generateTitleAndSubtitlePVImmo } from "./hypothese";
import EmojiVert from '../../../assets/images/svg/PvImmo/EMOJI_VERT_EXPORT.png';
import EmojiJaune from '../../../assets/images/svg/PvImmo/EMOJI_JAUNE_EXPORT.png';
import EmojiRouge from '../../../assets/images/svg/PvImmo/EMOJI_ROUGE_EXPORT.png';
import { numberFormat } from "../../all_ppt_render";

const title = (type) => {
    if (type === 0) return "CESSION DIRECTE";
    else if (type === 1) return "CESSION INDIRECTE";
    else return "CESSION DE PARTS DE SCI à l’IR";
}

export const generateComparaison = (pptx, slide, x, y, hypothese, resultat, emoji, compteur) => {
    const pourcentage = resultat.rapport_impot_plus_value_brute ? parseFloat(resultat.rapport_impot_plus_value_brute).toFixed(2) : 0;

    const dataChartAreaLine = [
        {
            name: "POURCENTAGE",
            labels: ["Impôt sur la plus-value", "NOT REAL"],
            values: [pourcentage, 100 - pourcentage],
        },
    ];

    // Entête
    generateTitleAndSubtitlePVImmo(
        "SIMULATION",
        pptx,
        slide,
        {
            text: [{ text: compteur >= 2 ? "COMPARAISON" : "RÉSULTATS SYNTHÉTIQUES", options: { fontSize: 14, bold: true } }],
            width: 4
        }
    )

    slide.addText(title(hypothese.type_cession), {
        x: x - 0.3,
        y: y + 0.4,
        w: 2.7,
        h: 0.4,
        fontFace: 'Roboto',
        align: 'left',
        fontSize: 12,
        bold: true,
        color: '4472C4'
    })

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x - 0.2,
        y: y + 0.8,
        fill: '4472C4',
        w: 0.5,
        h: 0.05,
        rectRadius: 0.4,
        line: {
            color: '4472C4'
        }
    });

    if (hypothese.nature_bien_cede !== 3) {
        // Nb d'années de détention
        slide.addText("Nombre d'années de détention", {
            x: x - 0.3,
            y: y + 1,
            fontFace: 'Roboto',
            w: 2.5,
            h: 0.5,
            bold: true,
            fontSize: 12,
            color: '343434'
        })

        slide.addText(
            `${resultat.nb_annee_pleines_detention && resultat.nb_annee_pleines_detention[0] && resultat.nb_annee_pleines_detention[0].annee ? resultat.nb_annee_pleines_detention[0].annee : 0}`
            ,
            {
                shape: pptx.ShapeType.roundRect,
                x: x + 2.2,
                y: y + 1,
                fill: "ECF1F9",
                color: "44546A",
                bold: true,
                fontSize: 12,
                fontFace: 'Roboto',
                rectRadius: 0.05,
                valign: 'middle',
                w: 0.6,
                h: 0.5
            });
    }
    else{
        // Cas Construction
        slide.addText(
            [
                { text: "Nb d'années", options: { breakLine: true } },
                { text: "de détention" }
            ], {
            x: x - 0.3,
            y: y + 1,
            fontFace: 'Roboto',
            w: 1.4,
            h: 0.5,
            bold: true,
            fontSize: 12,
            color: '343434'
        })
    
        slide.addText(
            [
                { text: `${resultat.nb_annee_pleines_detention && resultat.nb_annee_pleines_detention[0] && resultat.nb_annee_pleines_detention[0].annee ? resultat.nb_annee_pleines_detention[0].annee : 0}`, options: { breakLine: true, fontSize: 12 } },
                { text: "terrain", options: { fontSize: 9 } }
            ], 
            {
            shape: pptx.ShapeType.roundRect,
            x: x + 1.1,
            y: y + 1,
            fill: "ECF1F9",
            color: "44546A",
            bold: true,
            fontFace: 'Roboto',
            rectRadius: 0.05,
            valign: 'middle',
            w: 0.6,
            h: 0.5
        });
        
        slide.addText(
            [
                { text: `${resultat.nb_annee_pleines_detention && resultat.nb_annee_pleines_detention[1] && resultat.nb_annee_pleines_detention[1].annee ? resultat.nb_annee_pleines_detention[1].annee : 0}`, options: { breakLine: true, fontSize: 12 } },
                { text: "construction", options: { fontSize: 9 } }
            ], 
            {
            shape: pptx.ShapeType.roundRect,
            x: x + 1.8,
            y: y + 1,
            fill: "ECF1F9",
            color: "44546A",
            bold: true,
            fontFace: 'Roboto',
            rectRadius: 0.05,
            valign: 'middle',
            w: 1,
            h: 0.5
        });
    }

    // Plus-value brute
    slide.addText("Plus-value brute", {
        x: x - 0.3,
        y: y + 1.7,
        fontFace: 'Roboto',
        w: 2.5,
        h: 0.3,
        bold: true,
        fontSize: 12,
        color: '343434'
    })

    slide.addText(
        `${resultat.plus_value_brute ? numberFormat(resultat.plus_value_brute) : 0} €`
        ,
        {
            shape: pptx.ShapeType.roundRect,
            x: x - 0.2,
            y: y + 2,
            fill: "DAE3F3",
            color: "4472C4",
            bold: true,
            fontSize: 12,
            fontFace: 'Roboto',
            rectRadius: 0.05,
            valign: 'middle',
            w: 3,
            h: 0.5
        });

    // Imposition sur la plus-value immobilière
    slide.addText("Imposition sur la plus-value immobilière", {
        x: x - 0.3,
        y: y + 2.7,
        fontFace: 'Roboto',
        w: 3.5,
        h: 0.3,
        bold: true,
        fontSize: 12,
        color: '343434'
    });

    slide.addText(
        `${resultat.impositions_plus_value_immo ? numberFormat(resultat.impositions_plus_value_immo) : 0} €`
        ,
        {
            shape: pptx.ShapeType.roundRect,
            x: x - 0.2,
            y: y + 3,
            fill: "FACCCB",
            color: "F06460",
            bold: true,
            fontSize: 12,
            fontFace: 'Roboto',
            rectRadius: 0.05,
            valign: 'middle',
            w: 3,
            h: 0.7
        });

    // Graphe
    slide.addChart(
        pptx.ChartType.doughnut,
        dataChartAreaLine,
        {
            x: x + 1.7,
            y: y + 2.85,
            w: 1,
            h: 1,
            showPercent: false,
            holeSize: 90,
            chartColors: ['F06460', 'E0B3BD']
        }
    );

    slide.addText(
        `${pourcentage} %`
        ,
        {
            x: x + 1.7,
            y: y + 3.14,
            fontFace: 'Roboto',
            fontSize: 11,
            bold: true,
            align: 'center',
            w: 1,
            h: 0.4,
            color: 'F06460'
        })

    // IR
    slide.addText(`${resultat.ir ? numberFormat(resultat.ir) : 0} €`, {
        shape: pptx.ShapeType.roundRect,
        x: x - 0.2,
        y: y + 3.8,
        fill: "FDEEEE",
        color: "F06460",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2,
        h: 0.4
    });

    slide.addText("IR", {
        shape: pptx.ShapeType.roundRect,
        x: x + 1.8,
        y: y + 3.8,
        fill: "ADB9CA",
        color: "FFFFFF",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 1,
        h: 0.4
    });

    // PS
    slide.addText(`${resultat.ps ? numberFormat(resultat.ps) : 0} €`, {
        shape: pptx.ShapeType.roundRect,
        x: x - 0.2,
        y: y + 4.25,
        fill: "FDEEEE",
        color: "F06460",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2,
        h: 0.4
    });

    slide.addText("PS", {
        shape: pptx.ShapeType.roundRect,
        x: x + 1.8,
        y: y + 4.25,
        fill: "ADB9CA",
        color: "FFFFFF",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 1,
        h: 0.4
    });

    // Surtaxe
    slide.addText(`${resultat.sur_taxe ? numberFormat(resultat.sur_taxe) : 0} €`, {
        shape: pptx.ShapeType.roundRect,
        x: x - 0.2,
        y: y + 4.7,
        fill: "FDEEEE",
        color: "F06460",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2,
        h: 0.4
    });

    slide.addText("Surtaxe", {
        shape: pptx.ShapeType.roundRect,
        x: x + 1.8,
        y: y + 4.7,
        fill: "ADB9CA",
        color: "FFFFFF",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 1,
        h: 0.4
    });

    // CEHR
    slide.addText(`${resultat.cehr ? numberFormat(resultat.cehr) : 0} €`, {
        shape: pptx.ShapeType.roundRect,
        x: x - 0.2,
        y: y + 5.15,
        fill: "FDEEEE",
        color: "F06460",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2,
        h: 0.4
    });

    slide.addText("CEHR", {
        shape: pptx.ShapeType.roundRect,
        x: x + 1.8,
        y: y + 5.15,
        fill: "ADB9CA",
        color: "FFFFFF",
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 1,
        h: 0.4
    });

    // Prix net de cession
    slide.addText("Prix net de cession", {
        x: x - 0.3,
        y: y + 5.7,
        fontFace: 'Roboto',
        w: 2.5,
        h: 0.3,
        bold: true,
        fontSize: 12,
        color: '343434'
    })

    slide.addText(`${resultat.prix_net_cession ? numberFormat(resultat.prix_net_cession) : 0} €`, {
        shape: pptx.ShapeType.roundRect,
        x: x - 0.2,
        y: y + 6,
        fill: "DAE3F3",
        color: "4472C4",
        bold: true,
        fontSize: 12,
        fontFace: 'Roboto',
        rectRadius: 0.05,
        valign: 'middle',
        w: 2,
        h: 0.5
    });

    if (emoji) {
        slide.addImage({
            path: emoji === 'HAUT' ? EmojiVert : emoji === 'BAS' ? EmojiRouge : EmojiJaune,
            x: x + 2,
            y: y + 5.8,
            w: 0.7,
            h: 0.7
        })
    }

}