import { numberFormat } from "../../all_ppt_render";
import { generateTitleAndSubtitlePVImmo, titleLabel } from "./hypothese";

export const generatePageDetail1 = (pptx, slide, hypothese, ir, ps) => {
    const x = 0.7;
    const y = 1;
    const rowH = 0.3;//0.35;
    const emptyLineH = 0.003;
    const rowsHeight = [ rowH, rowH ];
    const rowsHeightConstruction = [ rowH, rowH ];
    const rowsHeight2 = [];
    const rowsHeightConstruction2 = [];


    const rowOptions = {
        fontSize: 11,
        fontFace: 'Roboto',
        valign: 'middle',
        margin: [ 0, 10, 0, 5 ]
    }

    const rightDashBorder = [
        {
            pt: 1,
            color: 'F2F2F2'
        },
        {
            pt: 1,
            color: '44546A',
            type: 'dash'
        },
        {
            pt: 1,
            color: 'F2F2F2'
        },
        {
            pt: 1,
            color: 'F2F2F2'
        }
    ]

    /***************************IMPOTS SUR LES REVENUS*********************************************** */
    const data = [
        [
            { text: "IMPOT SUR LE REVENU (IR)", options: { ...rowOptions, bold: true, color: 'F06460', fill: 'FFD7D5', colspan: 3 } },
        ],
        [
            { text: "Abattement pour durée de détention", options: { ...rowOptions, fill: 'F2F2F2' } },
            { text: `${ir.abattement_duree_detention_taux ? parseFloat(ir.abattement_duree_detention_taux).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } },
            { text: `${ir.abattement_duree_detention ? numberFormat(ir.abattement_duree_detention) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ]
    ];

    if(hypothese.abattemennt_exceptionnel !== 0){
        rowsHeight.push(rowH, rowH);
        data.push(
            [
                { text: "Abattement exceptionnel", options: { ...rowOptions, fill: 'F2F2F2' } },
                { text: `${ir.abattement_exceptionnel_taux ? parseFloat(ir.abattement_exceptionnel_taux).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } },
                { text: `${ir.abattement_exceptionnel ? numberFormat(ir.abattement_exceptionnel) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ],
            [
                { text: "Taux global d’abattement", options: { ...rowOptions, fill: 'F2F2F2', colspan: 2 } },
                { text: `${ir.taux_global_abattement ? parseFloat(ir.taux_global_abattement).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ],
        )
    }
    
    if(hypothese.type_cession === 0 && [2].includes(hypothese.nature_bien_cede)){
        rowsHeight.push(emptyLineH, rowH);
        data.push(
            [
                { text: "", options: { ...rowOptions, colspan: 3 } }
            ],
            [
                { text: "Forfait forestier", options: { ...rowOptions, fill: 'F2F2F2', colspan: 2 } },
                { text: `${ir.forfait_forestier ? numberFormat(ir.forfait_forestier) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ]
        )
    }
    
    rowsHeight.push(emptyLineH, rowH, rowH, rowH)
    data.push(
        [
            { text: "", options: { ...rowOptions, colspan: 3 } }
        ],
        [
            { text: "Plus-value nette imposable à l’IR", options: { ...rowOptions, fill: 'F2F2F2', colspan: 2 } },
            { text: `${ir.plus_value_nette_imposable_ir ? numberFormat(ir.plus_value_nette_imposable_ir) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "Taux forfaitaire IR", options: { ...rowOptions, fill: 'F2F2F2', colspan: 2 } },
            { text: `${ir.taux_forfait_ir ? parseFloat(ir.taux_forfait_ir).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "IR", options: { ...rowOptions, fill: 'F2F2F2', bold: true, color: 'F06460', colspan: 2 } },
            { text: `${ir.ir ? numberFormat(ir.ir) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', bold: true, color: 'F06460' } }
        ]
    )
    /*******************************FIN IMPOTS SUR LES REVENUS************************************** */

    /*******************************PRELEVEMENTS SOCIAUX********************************************* */
    const data2 = [];

    rowsHeight2.push(rowH, rowH);
    data2.push(
        [
            { text: "PRÉLÈVEMENTS SOCIAUX (PS)", options: { ...rowOptions, bold: true, color: 'F06460', fill: 'FFD7D5', colspan: 3 } },
        ],
        [
            { text: "Abattement pour durée de détention", options: { ...rowOptions, fill: 'F2F2F2' } },
            { text: `${ps.abattement_duree_detention_taux ? parseFloat(ps.abattement_duree_detention_taux).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } },
            { text: `${ps.abattement_duree_detention ? numberFormat(ps.abattement_duree_detention) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ]
    )
    
    if(hypothese.abattemennt_exceptionnel !== 0){
        rowsHeight2.push(rowH, rowH);
        data2.push(
            [
                { text: "Abattement exceptionnel", options: { ...rowOptions, fill: 'F2F2F2' } },
                { text: `${ps.abattement_exceptionnel_taux ? parseFloat(ps.abattement_exceptionnel_taux).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } },
                { text: `${ps.abattement_exceptionnel ? numberFormat(ps.abattement_exceptionnel) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ],
            [
                { text: "Taux global d’abattement", options: { ...rowOptions, fill: 'F2F2F2', colspan: 2 } },
                { text: `${ps.taux_global_abattement ? parseFloat(ps.taux_global_abattement).toFixed(3) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ],
        )
    }
    
    rowsHeight2.push(emptyLineH, rowH, rowH, rowH);
    data2.push(
        [
            { text: "", options: { ...rowOptions, colspan: 3 } }
        ],
        [
            { text: "Plus-value nette imposable aux PS", options: { ...rowOptions, fill: 'F2F2F2', colspan: 2 } },
            { text: `${ps.plus_value_nette_imposable_ir ? numberFormat(ps.plus_value_nette_imposable_ir) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "Taux PS", options: { ...rowOptions, fill: 'F2F2F2', colspan: 2 } },
            { text: `${ps.taux_ps ? parseFloat(ps.taux_ps).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "PS", options: { ...rowOptions, fill: 'F2F2F2', bold: true, color: 'F06460', colspan: 2 } },
            { text: `${ps.ps ? numberFormat(ps.ps) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', bold: true, color: 'F06460' } }
        ]
    )
    /***************************************************FIN PRELEVEMENTS SOCIAUX******************************** */

    /*************** CONSTRUCTION ************************** */
    // IR
    const dataConstruction = [
        [
            { text: "IMPOT SUR LE REVENU (IR)", options: { ...rowOptions, bold: true, color: 'F06460', fill: 'FFD7D5', colspan: 3 } },
        ],
        [
            { text: "Abattement pour durée de détention", options: { ...rowOptions, fill: 'F2F2F2', border: [...rightDashBorder] } },
            { text: `${ir.abattement_duree_detention ? numberFormat(ir.abattement_duree_detention) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', border: [...rightDashBorder] } },
            { text: `${ir.abattement_duree_detention_construction ? numberFormat(ir.abattement_duree_detention_construction) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } },
        ]
    ];
    
    // Construction
    if(hypothese.abattemennt_exceptionnel !== 0){
        rowsHeightConstruction.push(rowH, rowH);
        dataConstruction.push(
            [
                { text: "Abattement exceptionnel", options: { ...rowOptions, fill: 'F2F2F2', border: [...rightDashBorder] } },
                { text: `${ir.abattement_exceptionnel ? numberFormat(ir.abattement_exceptionnel) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', border: [...rightDashBorder] } },
                { text: `${ir.abattement_exceptionnel_construction ? numberFormat(ir.abattement_exceptionnel_construction) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } },
            ],
            [
                { text: "Taux global d’abattement", options: { ...rowOptions, fill: 'F2F2F2', border: [...rightDashBorder] } },
                { text: `${ir.taux_global_abattement ? parseFloat(ir.taux_global_abattement).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', border: [...rightDashBorder] } },
                { text: `${ir.taux_global_abattement_construction ? parseFloat(ir.taux_global_abattement_construction).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } },
            ],
        )
    }
    
    // Contruction
    if(hypothese.type_cession === 0 && [2].includes(hypothese.nature_bien_cede)){
        rowsHeightConstruction.push(emptyLineH, rowH);
        dataConstruction.push(
            [
                { text: "", options: { ...rowOptions, border: [...rightDashBorder] } },
                { text: "", options: { ...rowOptions, border: [...rightDashBorder] } },
                { text: "", options: { ...rowOptions } },
            ],
            [
                { text: "Forfait forestier", options: { ...rowOptions, fill: 'F2F2F2', border: [...rightDashBorder] } },
                { text: `${ir.forfait_forestier ? numberFormat(ir.forfait_forestier) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', border: [...rightDashBorder] } },
                { text: `${ir.forfait_forestier_construction ? numberFormat(ir.forfait_forestier_construction) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } },
            ]
        )
    }

    rowsHeightConstruction.push(emptyLineH, rowH, rowH, rowH)
    dataConstruction.push(
        [
            { text: "", options: { ...rowOptions, border: [...rightDashBorder] } },
            { text: "", options: { ...rowOptions, border: [...rightDashBorder] } },
            { text: "", options: { ...rowOptions } }
        ],
        [
            { text: "Plus-value nette imposable à l’IR", options: { ...rowOptions, fill: 'F2F2F2', border: [...rightDashBorder] } },
            { text: `${ir.plus_value_nette_imposable_ir ? numberFormat(ir.plus_value_nette_imposable_ir) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', border: [...rightDashBorder] } },
            { text: `${ir.plus_value_nette_imposable_ir_construction ? numberFormat(ir.plus_value_nette_imposable_ir_construction) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "Taux forfaitaire IR", options: { ...rowOptions, fill: 'F2F2F2', border: [...rightDashBorder] } },
            { text: `${ir.taux_forfait_ir ? parseFloat(ir.taux_forfait_ir).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', border: [...rightDashBorder] } },
            { text: `${ir.taux_forfait_ir_construction ? parseFloat(ir.taux_forfait_ir_construction).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
        ],
        [
            { text: "IR", options: { ...rowOptions, fill: 'F2F2F2', bold: true, color: 'F06460', border: [...rightDashBorder] } },
            { text: `${ir.ir ? numberFormat(ir.ir) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', bold: true, color: 'F06460', border: [...rightDashBorder] } },
            { text: `${ir.ir_construction ? numberFormat(ir.ir_construction) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', bold: true, color: 'F06460' } }
        ]
    )
    
    // PS
    const dataConstruction2 = [];

    rowsHeightConstruction2.push(rowH, rowH);
    dataConstruction2.push(
        [
            { text: "PRÉLÈVEMENTS SOCIAUX (PS)", options: { ...rowOptions, bold: true, color: 'F06460', fill: 'FFD7D5', colspan: 3 } },
        ],
        [
            { text: "Abattement pour durée de détention", options: { ...rowOptions, fill: 'F2F2F2', border: [...rightDashBorder] } },
            { text: `${ps.abattement_duree_detention ? numberFormat(ps.abattement_duree_detention) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', border: [...rightDashBorder] } },
            { text: `${ps.abattement_duree_detention_construction ? numberFormat(ps.abattement_duree_detention_construction) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } },
        ]
    )

    // Contruction
    if(hypothese.abattemennt_exceptionnel !== 0){
        rowsHeightConstruction2.push(rowH, rowH);
        dataConstruction2.push(
            [
                { text: "Abattement exceptionnel", options: { ...rowOptions, fill: 'F2F2F2', border: [...rightDashBorder] } },
                { text: `${ps.abattement_exceptionnel ? numberFormat(ps.abattement_exceptionnel) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', border: [...rightDashBorder] } },
                { text: `${ps.abattement_exceptionnel_construction ? numberFormat(ps.abattement_exceptionnel_construction) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ],
            [
                { text: "Taux global d’abattement", options: { ...rowOptions, fill: 'F2F2F2', border: [...rightDashBorder] } },
                { text: `${ps.taux_global_abattement ? parseFloat(ps.taux_global_abattement).toFixed(3) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', border: [...rightDashBorder] } },
                { text: `${ps.taux_global_abattement_construction ? parseFloat(ps.taux_global_abattement_construction).toFixed(3) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } }
            ],
        )
    }

    // Construction
    rowsHeightConstruction2.push(emptyLineH, rowH, rowH, rowH);
    dataConstruction2.push(
        [
            { text: "", options: { ...rowOptions, border: [...rightDashBorder] } },
            { text: "", options: { ...rowOptions, border: [...rightDashBorder] } },
            { text: "", options: { ...rowOptions } }
        ],
        [
            { text: "Plus-value nette imposable aux PS", options: { ...rowOptions, fill: 'F2F2F2', border: [...rightDashBorder] } },
            { text: `${ps.plus_value_nette_imposable_ir ? numberFormat(ps.plus_value_nette_imposable_ir) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', border: [...rightDashBorder] } },
            { text: `${ps.plus_value_nette_imposable_ir_construction ? numberFormat(ps.plus_value_nette_imposable_ir_construction) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } },
        ],
        [
            { text: "Taux PS", options: { ...rowOptions, fill: 'F2F2F2', border: [...rightDashBorder] } },
            { text: `${ps.taux_ps ? parseFloat(ps.taux_ps).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', border: [...rightDashBorder] } },
            { text: `${ps.taux_ps_construction ? parseFloat(ps.taux_ps_construction).toFixed(2) : 0} %`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right' } },
        ],
        [
            { text: "PS", options: { ...rowOptions, fill: 'F2F2F2', bold: true, color: 'F06460', border: [...rightDashBorder] } },
            { text: `${ps.ps ? numberFormat(ps.ps) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', bold: true, color: 'F06460', border: [...rightDashBorder] } },
            { text: `${ps.ps_construction ? numberFormat(ps.ps_construction) : 0} €`, options: { ...rowOptions, fill: 'F2F2F2', align: 'right', bold: true, color: 'F06460' } },
        ]
    )

    generateTitleAndSubtitlePVImmo(
        "SIMULATION",
        pptx,
        slide,
        {
            text: titleLabel(hypothese.type_cession),
            width: 6.55
        }
    );
    
    // PARTIE GAUCHE
    slide.addText('IMPOSITION DE LA PLUS-VALUE IMMOBILIÈRE', {
        x: x - 0.3,
        y: y + 0.4,
        w: 5,
        h: 0.4,
        fontFace: 'Roboto',
        align: 'left',
        fontSize: 13,
        bold: true,
        color: '4472C4'
    })

    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: x - 0.2,
        y: y + 0.8,
        fill: '4472C4',
        w: 0.5,
        h: 0.05,
        rectRadius: 0.4,
        line: {
            color: '4472C4'
        }
    });

    if(hypothese.nature_bien_cede !== 3){
        // IR
        slide.addTable(data, {
            x: x - 0.2,
            y: y + 1.1,
            colW: [ 2.7, 1.3, 1.3 ],
            rowH: rowsHeight
        })
    
        // PS
        slide.addTable(data2, {
            x: x - 0.2,
            y: y + 4.1,
            colW: [ 2.7, 1.3, 1.3 ],
            rowH: rowsHeight2
        })
    }
    else{
        /******************************CAS CONSTRUCTION***************************** */
        slide.addText("terrain", {
            x: x + 2.75,
            y: y + 0.8,
            fontFace: 'Roboto',
            bold: true,
            underline: true,
            fontSize: 11,
            h: 0.3,
            w: 0.75,
            valign: 'middle',
            align: 'center'
        })

        slide.addText("construction", {
            x: x + 3.9,
            y: y + 0.8,
            fontFace: 'Roboto',
            bold: true,
            underline: true,
            fontSize: 11,
            h: 0.3,
            w: 1.1,
            valign: 'middle',
            align: 'center'
        })
        // IR
        slide.addTable(dataConstruction, {
            x: x - 0.2,
            y: y + 1.1,
            colW: [ 2.7, 1.3, 1.3 ],
            rowH: rowsHeightConstruction
        })
    
        // PS
        slide.addTable(dataConstruction2, {
            x: x - 0.2,
            y: y + 4.1,
            colW: [ 2.7, 1.3, 1.3 ],
            rowH: rowsHeightConstruction2
        })
    }
    /******************************FIN Cas Construction*************************** */

    // PARTIE DROITE
    slide.addText("DÉTAIL FISCALITÉ", {
        shape: pptx.ShapeType.roundRect,
        fill: '4472C4',
        rectRadius: 0.05,
        x: x + 8.56,
        y: y + 0.4,
        align: 'center',
        valign: 'middle',
        w: 1.8,
        h: 0.3,
        fontFace: 'Roboto',
        fontSize: 12,
        bold: true,
        color: 'FFFFFF'
    });

    const commentaires1 = [
        { text: "Commentaires", options: { breakLine: true, bold: true } },
    ];

    if(hypothese.nature_bien_cede === 3){
        commentaires1.push(
            { text: "", options: { breakLine: true } },
            { text: `Pour le terrain, le taux de l’abattement pour durée de détention est de ${ir.abattement_duree_detention_taux ? parseFloat(ir.abattement_duree_detention_taux).toFixed(2) : 0} %.`, options: { breakLine: true, bullet: { code: '25CF' } } },
            { text: "", options: { breakLine: true } },
            { text: `Pour la construction, le taux de l’abattement pour durée de détention est de ${ir.abattement_duree_detention_taux_construction ? parseFloat(ir.abattement_duree_detention_taux_construction).toFixed(2) : 0} %.`, options: { breakLine: true, bullet: { code: '25CF' } } }
        )

        if(hypothese.abattemennt_exceptionnel !== 0){
            commentaires1.push(
                { text: "", options: { breakLine: true } },
                { text: `Le taux de l'abattement exceptionnel est de ${ir.abattement_exceptionnel_taux ? parseFloat(ir.abattement_exceptionnel_taux).toFixed(2) : 0} %.`, options: { breakLine: true, bullet: { code: '25CF' } } },
            );
        }
    }

    if(hypothese.abattemennt_exceptionnel !== 0){
        commentaires1.push(
            { text: "", options: { breakLine: true } },
            { text: `L'abattement exceptionnel est appliqué sur la plus-value après déduction de l'abattement pour durée de détention.`, options: { breakLine: true, bullet: { code: '25CF' } } },
        )
    }

    if(hypothese.type_cession === 0 && [2].includes(hypothese.nature_bien_cede)){
        commentaires1.push(
            { text: "", options: { breakLine: true } },
            { text: `Le forfait forestier est de 10 € par hectare vendu. Il s'applique uniquement pour le calcul de la plus-value imposable à l'IR.`, options: { breakLine: true, bullet: { code: '25CF' } } },
        )
    }
    
    const commentaires2 = [
        { text: "Commentaires", options: { breakLine: true, bold: true } },
    ]

    if(hypothese.nature_bien_cede === 3){
        commentaires2.push(
            { text: "", options: { breakLine: true } },
            { text: `Pour le terrain, le taux de l’abattement pour durée de détention est de ${ps.abattement_duree_detention_taux ? parseFloat(ps.abattement_duree_detention_taux).toFixed(2) : 0} %.`, options: { breakLine: true, bullet: { code: '25CF' } } },
            { text: "", options: { breakLine: true } },
            { text: `Pour la construction, le taux de l’abattement pour durée de détention est de ${ps.abattement_duree_detention_taux_construction ? parseFloat(ps.abattement_duree_detention_taux_construction).toFixed(2) : 0} %.`, options: { breakLine: true, bullet: { code: '25CF' } } }
        )

        if(hypothese.abattemennt_exceptionnel !== 0){
            commentaires2.push(
                { text: "", options: { breakLine: true } },
                { text: `Le taux de l'abattement exceptionnel est de ${ps.abattement_exceptionnel_taux ? parseFloat(ps.abattement_exceptionnel_taux).toFixed(2) : 0} %.`, options: { breakLine: true, bullet: { code: '25CF' } } },
            );
        }
    }

    if(hypothese.abattemennt_exceptionnel !== 0){
        commentaires2.push(
            { text: "", options: { breakLine: true } },
            { text: `L'abattement exceptionnel est appliqué sur la plus-value après déduction de l'abattement pour durée de détention`, options: { breakLine: true, bullet: { code: '25CF' } } },
        )
    }

    if(commentaires1.length > 1){
        slide.addText(
            commentaires1,{
                x: x + 5.5,
                y: y + 1.1,
                fontFace: 'Roboto',
                fontSize: 11,
                color: '44546A',
                fill: 'FFF8E2',
                valign: 'top',
                w: 4.9,
                h: 2.3
            }
        )
    }
    
    if(commentaires2.length > 1){
        slide.addText(
            commentaires2,{
                x: x + 5.5,
                y: y + 4.1,
                fontFace: 'Roboto',
                fontSize: 11,
                color: '44546A',
                fill: 'FFF8E2',
                valign: 'top',
                w: 4.9,
                h: 2.3
            }
        )
    }
}